(function () {
  angular
    .module('app.general')
    .directive('scUserActionsDropdown', scUserActionsDropdownDirective)
    .controller('UserActionsDropdownController', UserActionsDropdownController);

  function scUserActionsDropdownDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'general/views/scUserActionsDropdownDirectiveView.html',
      controller: 'UserActionsDropdownController',
      controllerAs: '$ctrl',
      scope: true,
    };
  }

  function UserActionsDropdownController(
    $q,
    $scope,
    $window,
    UserService,
    $uibModal,
    VideoUsageTracker,
  ) {
    this.initialize = function () {
      const SPORT_ID_HOCKEY = 4;
      this.user = UserService.getUser();
      this.account = UserService.getActiveAccount();
      this.activeSport = parseInt(localStorage.getItem('scActiveSport')) || SPORT_ID_HOCKEY;
    };

    this.signOut = signOut;
    this.equals = angular.equals;
    this.changeAccount = changeAccount;
    this.changeSport = changeSport;

    this.transformRoleName = function (role) {
      return role.display;
    };

    this.hasAccountFunctions = function () {
      return (
        this.account &&
        this.account.functions &&
        this.account.functions.filter((item) => !!item).length > 0
      );
    };

    this.initialize();
    $scope.$on('userDataUpdated', this.initialize.bind(this));

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    $q.all([UserService.getAvailableAccounts(), VideoUsageTracker.fetchUsagesWithAccounts()]).then(
      function ([availableAccounts, accountsUsages]) {
        vm.availableAccounts = availableAccounts.map((acc) => {
          return {
            ...acc,
            usage: accountsUsages.find((usage) => usage.account._id === acc._id),
          };
        });
      },
    );

    function signOut() {
      UserService.logout();
    }

    function changeAccount(account) {
      if (vm.equals(vm.account, account)) {
        return;
      }

      const modalInstance = $uibModal.open({
        size: 'sm',
        template: '<busy-modal text="general.switching_account"/>',
        backdrop: 'static',
        keyboard: false,
      });

      UserService.switchToAccount(account)
        .then(() => {
          $window.location = '/platform/countries';
        })
        .catch(() => {
          modalInstance.close();
        });
    }

    function changeSport(sport) {
      if (vm.equals(vm.activeSport, sport.id)) {
        return;
      }

      const modalInstance = $uibModal.open({
        size: 'sm',
        template: '<busy-modal text="Loading ..."/>',
        backdrop: 'static',
        keyboard: false,
      });

      localStorage.setItem('scActiveSport', sport.id);

      $window.location = '/platform/countries';
    }
  }
})();
